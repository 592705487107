import { Component, OnInit } from '@angular/core';
import { AdxadModalRef } from '../../../../ui/modules/modal/modal-ref';
import { AdxadModalConfig } from '../../../../ui/modules/modal/modal-config';

@Component({
  selector: 'adxad-publisher-blocking-confirm',
  templateUrl: './publisher-blocking-confirm.component.html',
  styleUrls: ['./publisher-blocking-confirm.component.scss']
})
export class PublisherBlockingConfirmComponent implements OnInit {
  constructor(
    private modalRef: AdxadModalRef,
    public config: AdxadModalConfig
  ) {}

  ngOnInit(): void {
    if (!this.config.data) {
      this.closeModal();
    }
  }

  /**
   * Close modal
   * if submit == true, grid will reload
   *
   * @param {boolean} submit
   */
  closeModal(submit?: boolean): void {
    this.modalRef.close({ submit: submit });
  }
}
