<adxad-modal-close-btn (click)="closeModal()"></adxad-modal-close-btn>

<adxad-modal-content>
  Do you want to block the <br />
  {{ config.data.publisher.username }}?
</adxad-modal-content>

<adxad-modal-actions>
  <button
    adxadStrokedButton
    (click)="closeModal()"
  >
    No
  </button>
  <button
    adxadButton
    (click)="closeModal(true)"
  >
    Yes
  </button>
</adxad-modal-actions>
