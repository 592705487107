<adxad-loader
  adxadModalLoader
  [diameter]="40"
  *ngIf="isLoading"
></adxad-loader>

<ng-container *ngIf="!isLoading">
  <adxad-modal-close-btn (click)="closeModal()"></adxad-modal-close-btn>

  <adxad-modal-header>
    <h2 adxadModalTitle>Set manager</h2>
  </adxad-modal-header>

  <adxad-modal-content>
    <form [formGroup]="form">
      <adxad-form-field class="field-group">
        <adxad-select
          placeholder="Select account manager"
          formControlName="manager"
          label="Account manager"
          [options]="dicts.accountManagers.data"
        ></adxad-select>
        <adxad-show-errors [control]="form.get('manager')"></adxad-show-errors>
      </adxad-form-field>
    </form>
  </adxad-modal-content>

  <adxad-modal-actions>
    <button
      adxadStrokedButton
      (click)="closeModal()"
    >
      {{ 'cancel' | transloco }}
    </button>

    <button
      adxadButton
      [disabled]="!form.valid || !form.dirty || isLoadingSubmit"
      (click)="submit()"
    >
      <adxad-loader
        class="submit-loader"
        [diameter]="20"
        *ngIf="isLoadingSubmit"
      ></adxad-loader>
      <span [ngClass]="{ loading: isLoadingSubmit }"> Save </span>
    </button>
  </adxad-modal-actions>
</ng-container>
