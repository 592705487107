import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { AdxadModalRef } from '../../../../ui/modules/modal/modal-ref';
import { AdxadModalConfig } from '../../../../ui/modules/modal/modal-config';
import { AlertsComponent } from '../../../../ui/components/alerts/alerts.component';
import { MessageService } from '../../../../core/services/message.service';
import { SspService } from '../../../../core/services/ssp.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { PublishersService } from '../../publishers.service';
import { Dicts } from '../../../../core/interfaces';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'adxad-manager-form',
  templateUrl: './manager-form.component.html',
  styleUrls: ['./manager-form.component.scss']
})
export class ManagerFormComponent implements OnInit {
  public form: UntypedFormGroup;
  public isLoading = false;
  public isLoadingSubmit = false;
  public dicts = {} as Dicts;

  private destroyRef = inject(DestroyRef);

  constructor(
    private modalRef: AdxadModalRef,
    public config: AdxadModalConfig,
    private alerts: AlertsComponent,
    private messageService: MessageService,
    private sspService: SspService,
    private fb: UntypedFormBuilder,
    private publishersService: PublishersService
  ) {}

  ngOnInit(): void {
    this.createForm();
    this.loadData();
  }

  /**
   * Create reactive form
   */
  createForm() {
    this.form = this.fb.group({
      manager: ['', Validators.required]
    });
  }

  /**
   * Load dicts
   * If error -> close modal
   */
  loadData(): void {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;

    this.sspService
      .getManagers({ limit: 200, page: 1 })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: result => {
          this.dicts.accountManagers = result;
        },
        error: () => {
          this.isLoading = false;
          this.closeModal();
        },
        complete: () => (this.isLoading = false)
      });
  }

  /**
   * Close modal
   * if submit == true, grid will reload
   *
   * @param {boolean} submit
   */
  closeModal(submit?: boolean): void {
    this.modalRef.close({ submit: submit });
  }

  /**
   * Submit manager form
   */
  submit() {
    if (!this.form.valid || this.isLoadingSubmit) {
      return;
    }

    this.isLoadingSubmit = true;
    const data = this.form.value;
    data['ids'] = this.config.data.ids;

    this.publishersService
      .multiChangeManager(data)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: response => {
          if (response.status === 'OK') {
            this.modalRef.close(response);
            this.alerts.success('Manager successfully changed', 3000);
            this.messageService.add('reload-publishers-grid', { submit: true });
          }

          if (response.hasOwnProperty('errors')) {
            Object.keys(response.errors).forEach(key => {
              this.alerts.error(response.errors[key], 3000);
            });
          }
        },
        error: () => (this.isLoadingSubmit = false),
        complete: () => (this.isLoadingSubmit = false)
      });
  }
}
